import React from 'react'
import { Space } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';

const GrantingDisplay = props => {
  const {
    data
  } = props;
  return (
    <>
      <p>
        {data.population && data.population.job_families && 
          <div>Job families: <b>{data.population.job_families}</b></div>
        }
        {data.population && data.population.working_place_legal_entities && 
          <div>Working Place Legal Entity: <b>{data.population.working_place_legal_entities}</b></div>
        }
        {data.population && data.population.contract_legal_entities && 
          <div>Contract Legal Entity: <b>{data.population.contract_legal_entities}</b></div>
        }
        {data.population && data.population.business_units && 
          <div>Region/BU/Corp Function: <b>{data.population.business_units}</b></div>
        }
        {data.population && data.population.leadership_team && 
          <div>Leadership Team: <b>{data.population.leadership_team}</b></div>
        }
        {data.population && data.population.expat && 
          <div>Expatriate: <b>{data.population.expat}</b></div>
        }
        {data.population && data.population.hidden_population && 
          <div>Hidden Population: <b>{data.population.hidden_population}</b></div>
        }
      </p>
      <p>
        <div>Permission: <b>{data.permission_group}</b></div>
        {data.permissions && data.permissions.map(permission => (
          <div>
            <Space>
              <CheckCircleTwoTone twoToneColor="#52c41a" />
              {I18n.t(`activerecord.attributes.permission_group.${permission}`)}
            </Space>
          </div>
        ))}
      </p>
    </>
  )
}

export default GrantingDisplay;