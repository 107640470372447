import React from 'react';
import {
  Col,
  Row,
} from 'antd';
import VirtualListAntd from '../utils/virutalListAntd';

const renderHcSumLabel = (headcountSum) => {
  switch(Math.sign(headcountSum)) {
    case 0:
      return(
        <div className="headcount-label hoverable positive">
          {headcountSum}
        </div>
      );
    case -1:
      return(
        <div className="headcount-label hoverable negative">
          {headcountSum}
        </div>
      );
    default:
      return(
        <div className="headcount-label hoverable positive">
          +{headcountSum}
        </div>
      );
  }
}

const MovesReportingTable = (props) => {
  const {
    bu_headers,
    data,
    display_contract_legal_entity,
    headcounts_type,
    headcount_report_scope,
    month_from,
    month_to
  } = props;

  var columns = []

  const renderCellContent = (totalBefore, totalAfter, headcountSum, contract_legal_entity_id, bu_id, jf_id) => {

    var headcountDetailsHref = `/budget/headcounts_details?headcount_report_scope=${headcount_report_scope}&headcounts_type=${headcounts_type}`
    headcountDetailsHref += `&contract_category=${headcounts_type}`
    headcountDetailsHref += `&month_from=${month_from}`
    headcountDetailsHref += `&month_to=${month_to}`
    if(bu_id) {
      headcountDetailsHref += `&business_unit_id=${bu_id}`
    }
    if(jf_id) {
      headcountDetailsHref += `&job_family_id=${jf_id}`
    }
    if(display_contract_legal_entity && contract_legal_entity_id) {
      headcountDetailsHref += `&contract_legal_entity_id=${contract_legal_entity_id}`
    }

    var employeesDetailsHref = `/budget/employees_detail?headcount_report_scope=${headcount_report_scope}`
    employeesDetailsHref += `&contract_category=${headcounts_type}`
    if(bu_id) {
      employeesDetailsHref += `&business_unit_id=${bu_id}`
    }
    if(jf_id) {
      employeesDetailsHref += `&job_family_id=${jf_id}`
    }
    if(display_contract_legal_entity && contract_legal_entity_id) {
      employeesDetailsHref += `&contract_legal_entity_id=${contract_legal_entity_id}`
    }
    var employeesDetailsBeforeHref = employeesDetailsHref + `&month=${month_from}`
    var employeesDetailsAfterHref = employeesDetailsHref + `&month=${month_to}`

    return (
      <div 
        className="flex align-items-center justify-content-space-evenly"
        style={{ width: '250px', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {headcountSum && !isNaN(headcountSum) ? (
          <>
            <div className="headcount-data">
              {/* { totalBefore.toFixed(1) } */}
              <a href={employeesDetailsBeforeHref} data-remote>
                { totalBefore }
              </a>
            </div>
            <div className="headcount-data">
              {/* { totalAfter.toFixed(1) } */}
              <a href={employeesDetailsAfterHref} data-remote>
                { totalAfter }
              </a>
            </div>
            <a href={headcountDetailsHref} data-remote>
              {renderHcSumLabel(headcountSum)}
            </a>
          </>
        ) : (
          <>
            {totalBefore === totalAfter ? (
              <>
                {/* {totalBefore ? totalBefore.toFixed(1) : '-'} */}
                <a href={employeesDetailsBeforeHref} data-remote>
                  {totalBefore ? totalBefore : '-'}
                </a>
              </>
            ) : (
              <>
                <div className="headcount-data">
                  {/* {isNaN(totalBefore) ? '-' : totalBefore.toFixed(1) } */}
                  {isNaN(totalBefore) ? '-' : 
                    <a href={employeesDetailsBeforeHref} data-remote>
                      {totalBefore}
                    </a>
                  }
                </div>
                <div className="headcount-data">
                  {/* {isNaN(totalAfter) ? '-' : totalAfter.toFixed(1) } */}
                  {isNaN(totalAfter) ? '-' : 
                    <a href={employeesDetailsAfterHref} data-remote>
                      {totalAfter}
                    </a>
                  }
                </div>
              </>
            )}
          </>
        )}
      </div>
    )
  }

  if(display_contract_legal_entity) {
    columns.push(
      {
        title: 'Contract Legal Entity',
        key: 'contract_legal_entities',
        fixed: 'left',
        ellipsis: true,
        onCell: (record) => ({
          className: record.job_family_id ? null : 'total-column'
        }),
        render: (record) => (
          <div style={{ width: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {record.contract_legal_entity}
          </div>
        ),
      }
    )
  }

  columns.push({
    title: 'Category',
    key: 'job_family_category',
    fixed: 'left',
    ellipsis: true,
    onCell: (record) => ({
      className: record.job_family_id ? null : 'total-column'
    }),
    render: (record) => (
      <div style={{ width: '150px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {record.job_family_category}
      </div>
    ),
  })

  columns.push({
    title: 'Job Family',
    key: 'job_families',
    fixed: 'left',
    ellipsis: true,
    onCell: (record) => ({
      className: record.job_family_id ? null : 'total-column'
    }),
    render: (record) => (
      <div style={{ width: '150px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {record.job_family}
      </div>
    ),
  })

  bu_headers && bu_headers.forEach((businessUnit) => {
    columns.push({
      title: () => (
        <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', wordWrap: 'break-word', wordBreak: 'break-word' }}>
          {businessUnit?.title}
        </div>
      ),
      key: `header_${businessUnit?.title}`,
      align: 'center',
      width: 250,
      ellipsis: true,
      onCell: (record) => ({
        className: record.job_family_id ? null : 'total-column'
      }),
      render: (record) => {
        var totalBefore = record[`${businessUnit?.index}_before`]
        var totalAfter = record[`${businessUnit?.index}_after`]
        var headcountSum = record[`${businessUnit?.index}_headcount_sum`]
        var businessUnitId = businessUnit?.index ? businessUnit.index.split('_')[1] : null
        return renderCellContent(totalBefore, totalAfter, headcountSum, record.contract_legal_entity_id, businessUnitId, record.job_family_id);
      },
    })
  })

  columns.push({
    title: 'Total',
    key: 'jf_total',
    align: 'center',
    width: 250,
    ellipsis: true,
    onCell: (record) => ({
      className: 'total-column'
    }),
    render: (record) => {
      var totalBefore = record.bu_total_before
      var totalAfter = record.bu_total_after
      var headcountSum = record.bu_total_headcount_sum
      var businessUnitId = null
      return renderCellContent(totalBefore, totalAfter, headcountSum, record.contract_legal_entity_id, businessUnitId, record.job_family_id);
    },
  })

  return (
    <Row>
      <Col lg={bu_headers && bu_headers.length < 2 ? 16 : 24}>
        <VirtualListAntd
          columns={columns}
          dataSource={data}
          pagination={false}
          bordered
        />
        {/* <Table
          dataSource={data}
          columns={columns}
          rowKey="id"
          bordered
          size="middle"
          sticky
          scroll={{ x: "max-content" }}
          tableLayout="fixed"
          pagination={false}
        /> */}
      </Col>
    </Row>
  );
};

export default MovesReportingTable;
