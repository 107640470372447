import React, { useState } from 'react'
import {
  Table,
  Popover,
  Dropdown,
  Menu,
  Space
} from 'antd';
import {
  EllipsisOutlined,
} from '@ant-design/icons';
import DataList from '../UsersTable/DataList';
import { showConfirm } from '../utils/showConfirm';


const GrantingsTable = props => {
  const { 
    grantings,
    can_display_versions,
  } = props

  const [showMorePerimetersIds, setShowMorePerimetersIds] = useState([])

  const showMorePerimeters = (recordId) => {
    if(!showMorePerimetersIds.includes(recordId)) {
      setShowMorePerimetersIds([...showMorePerimetersIds, recordId])
    }
  }

  const columns = [
    {
      title: 'User',
      key: 'user',
      fixed: 'left',
      width: 250,
      render: (record) => (
        <>
          <div className="flex align-items-flex-start">
            <b>{record?.user?.fullname}</b>
            {record?.user?.record_type === 'api' &&
              <Popover placement="right" content="This user is used to give access to it perimeter defined by grantings to an api key.">
                <div className="status-label ml-10">
                  <b>API</b>
                </div>
              </Popover>
            }
          </div>
          <div className="text-small">{record?.user?.email}</div>
        </>
      )
    },
    {
      title: 'Permission Group',
      key: 'permission_group',
      width: 100,
      render: (record) => (
        record?.permission_group?.title 
        ? (
          <Popover content="Display permission group" mouseEnterDelay={0} mouseLeaveDelay={0}>
            <a href={`/settings/permission_groups?[q]title_i_cont=${record.permission_group.title}`}>
              {record.permission_group.title}
            </a>
          </Popover>
        )
        : '-'
      )
    },
    {
      title: 'Title',
      key: 'title',
      dataIndex: 'title',
    },
    {
      title: 'Perimeter',
      key: 'roles',
      ellipsis: true,
      render: (record) => (
        <>
          {record.perimeter.length > 0 ? (
            <Space size={[8,8]} wrap>
              {record.perimeter.slice(0,3).map((perimeter) => (
                Array.isArray(perimeter.value) ? (
                  <Popover content={<DataList list={perimeter.value} />} mouseEnterDelay={0} mouseLeaveDelay={0}>
                    <div className="ui basic label">
                      {perimeter.title} : {perimeter.value.length}
                    </div>
                  </Popover>
                ) : (
                  <div className="ui basic label">
                    {perimeter.value}
                  </div>
                )
              ))}
              {record.perimeter.slice(3).length > 0 && (
                showMorePerimetersIds.includes(record.id) ? (
                  record.perimeter.slice(3).map((perimeter) => (
                    Array.isArray(perimeter.value) ? (
                      <Popover content={<DataList list={perimeter.value} />} mouseEnterDelay={0} mouseLeaveDelay={0}>
                        <div className="ui basic label">
                          {perimeter.title} : {perimeter.value.length}
                        </div>
                      </Popover>
                    ) : (
                      <div className="ui basic label">
                        {perimeter.value}
                      </div>
                    )    
                  ))
                ) : (
                  <Popover content="Click to display" mouseEnterDelay={0} mouseLeaveDelay={0}>
                    <a onClick={() => showMorePerimeters(record.id)}>
                      +{record.perimeter.slice(3).length} other
                    </a>
                  </Popover>
                )
              )}
            </Space>
          )
          : '-' }
        </>
      )
    },
    {
      title: 'Permissions',
      key: 'permissions',
      ellipsis: true,
      render: (record) => {
        if (record.permissions.length > 3) {
          return (
            <Popover content={<DataList list={record.permissions}/>} mouseEnterDelay={0} mouseLeaveDelay={0}>
              <div className="ui basic label">
                {record.permissions.length} permissions
              </div>
            </Popover>
          )
        } else if (record.permissions.length > 0) {
          return (
            record.permissions.map(permission => (
              <div className="ui basic label">
                {permission.title}
              </div>
            ))
          )
        } else {
          return '-'
        }
      }
    },
    {
      key: 'actions',
      align: 'right',
      width: 54,
      fixed: 'right',
      render: (record) => (
        <Dropdown
          overlay={
            <Menu>
              {record.can_update &&
                <Menu.Item key="editGranting">
                  <a href={`/settings/grantings/${record.id}/edit`} data-remote="true">
                    <i className="fas fa-pen mr-5"/>
                    {I18n.t("edit")}
                  </a>
                </Menu.Item>
              }
              {can_display_versions &&
                <Menu.Item key="displayGrantingHistory">
                  <a href={`/grantings/${record.id}/versions`} data-remote="true">
                    <i className="fas fa-history mr-5"/>
                    {I18n.t("versions.display_versions")}
                  </a>
                </Menu.Item>
              }
              {record?.user?.id &&
                <Menu.Item key="viewGrantingUser">
                  <a href={`/settings/users?search=${record?.user?.email}`}>
                    <i className="fas fa-user mr-5"/>
                    View user
                  </a>
                </Menu.Item>
              }
              <Menu.Item
                key="deleteGranting"
                disabled={!record.deletable}
              >
                <a onClick={() => showConfirm(`deleteGranting${record.id}`)}>
                  <i className="fas fa-trash mr-5"/>
                  {I18n.t("destroy")}
                </a>
                <a style={{ display: 'none' }} href={`/settings/grantings/${record.id}`} id={`deleteGranting${record.id}`} data-method="delete" data-remote="true" />
              </Menu.Item>
            </Menu>
          }
        >
          <span className="ellipsis-container">
            <EllipsisOutlined rotate={90} style={{ fontSize: 15 }} />
          </span>
        </Dropdown>
      ),
    },
  ]

  return (
    <>
      <Table
        className="vertical-align-top"
        dataSource={grantings}
        columns={columns}
        rowKey="id"
        pagination={false}
        size="middle"
        bordered
        sticky
        scroll={{ x: true }}
      />
    </>
  )
}

export default GrantingsTable;